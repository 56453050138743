const initialState = {
    sidebarShow: true,
    sidebarUnfoldable: false
  }
  
  const changeHeaderState = (state = initialState, { type, ...rest }) => {
    switch (type) {
      case 'SIDEBAR_SHOW':
        return { ...state, ...rest }
      default:
        return state
    }
  }

  export {
    changeHeaderState
};