const chargingStatusInitialStatus = {
    "charging_transaction_id": null,
    "transaction_id": null,
    "charging_OTP": null,
    "elapsed_time": 0,
    // "start_time": null,
    // "stop_time": null,
    "at": "2nd if",
    "message": "",
    "kwh": 0,
    "soc": null,
    "voltage": null,
    "current": null,
    "power": null,
    "max_kwh": 0,
    "status": "Initiate",
    "debug_status": "PENDING",
    "elapsedTimeHM":"00 : 00",
    "isOTPRequired": false,
    "last_update": "21-11-2023 |04:41 PM"
}

const chargingTransDetailModelInitialSize = {"size": "md"}

function convertInHM (value){
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours   = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes; // Return is HH : MM
}

const chargingStatus = (state = chargingStatusInitialStatus, action) => {
    switch (action.type) {
        case 'SET_INITIAL_CHARGING_STATUS_SUCCESS':
            return chargingStatusInitialStatus;
        case 'SET_LIVE_CHARGING_DATA_GET_SUCCESS':
            state = {
                ...state,
                ...action.payload,
                elapsedTimeHM: convertInHM(action.payload.elapsed_time)
            };
            return state;
        default:
            return state
    }
}

const modelSize = (state = chargingTransDetailModelInitialSize, action) => {
    switch (action.type) {
        case 'SET_CHARGING_TRANS_DETAIL_MODEL_SIZE':
            return action.payload
        default:
            return state
    }
}

export {
    chargingStatus,
    modelSize,
  };