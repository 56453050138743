const organizationListInitialState = {
  organization: [],
  pagination: {}
}

const organizationList = (state = organizationListInitialState, action) => {
  switch (action.type) {
    case 'SET_ORGANIZATION_LIST_SUCCESS':
      return {
        ...state,
        organization: action.payload.organization,
        pagination: {
          totalPages: action.payload.totalPages
        }
      }
    default:
      return state
  }
}

export {
  organizationList
};