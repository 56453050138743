const initialState = {
    socket: null,
    messages: [],
  };
  
  const websocketReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'WEBSOCKET_CONNECTED':
        return {
          ...state,
          socket: action.payload,
        };
      case 'WEBSOCKET_MESSAGE_RECEIVED':
        return {
          ...state,
          messages: [...state.messages, action.payload],
        };
      case 'WEBSOCKET_CLOSED':
        return {
          ...state,
          socket: null,
        };
      default:
        return state;
    }
  };
  
  export {
    websocketReducer
  };
  