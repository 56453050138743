import { commonConstants } from '../constants/constants';

const defaultAuthState = {
    isUserLogin: false,
    userLoginError: {}
}

function AuthState(prevState = defaultAuthState, action) {

    switch (action.type) {
        // case commonConstants.Restore_Token_and_User:
        //     return {
        //         ...prevState,
        //         isUserLogin: action.data.token,
        //         userLoginError : action.payload
            // };
        case commonConstants.LOGIN_SUCCESS:
            return {
                ...prevState,
                isUserLogin: true,
                userLoginError : {}
            };
        case commonConstants.LOGIN_FAILED:
            return {
                ...prevState,
                userLoginError : action.payload
            };
        case commonConstants.LOGOUT:
            return {
                ...prevState,
                isUserLogin: false,
                userLoginError: {}
            };
        default:
            return prevState
    }
}



export {
    AuthState
};