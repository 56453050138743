const chargingSummaryInitialState = {}
const initiateChargingSummaryState = {}

const chargingSummary = (state = chargingSummaryInitialState, action) => {
    switch (action.type) {
      case 'SET_CHARGING_SUMMARY_DATA_SUCCESS':
        return action.payload
      default:
        return state
    }
  }

  const initiateCharging = (state = initiateChargingSummaryState, action) => {
    switch (action.type) {
      case 'SET_INITIATE_CHARGING_DATA_SUCCESS':
        return action.payload
      default:
        return state
    }
  }

  export {
    chargingSummary,
    initiateCharging,
  };