const paymentHistoryInitialState = {
    paymentTransactionList: [],
    pagination: {}
  }

  const walletHistoryInitialState = {
    walletTransactionList: [],
    pagination: {}
  }

  const paymentTransactionDetailsInitialState = {}

  const walletHistoryData = (state = walletHistoryInitialState, action) => {
    switch (action.type) {
      case 'SET_WALLET_HISTORY_SUCCESS':
        return {
          ...state,
          walletTransactionList: action.payload.walletTransactionList,
          pagination: {
            currentPage: action.payload.currentPage,
            totalPages: action.payload.totalPages
          }
        }
      case 'RESET_WALLET_HISTORY':
        return walletHistoryInitialState
      default:
        return state
    }
  }

  const paymentHistoryData = (state = paymentHistoryInitialState, action) => {
    switch (action.type) {
      case 'SET_PAYMENT_HISTORY_SUCCESS':
        return {
          ...state,
          paymentTransactionList: action.payload.paymentTransactionList,
          pagination: {
            currentPage: action.payload.currentPage,
            totalPages: action.payload.totalPages
          }
        }
      case 'RESET_PAYMENT_HISTORY':
        return paymentHistoryInitialState
      default:
        return state
    }
  }

  const paymentTransactionDetails = (state = paymentTransactionDetailsInitialState, action) => {
    switch (action.type) {
      case 'SET_PAYMENT_TRANSACTION_DETAILS_SUCCESS':
        return action.payload
      case 'RESET_PAYMENT_TRANSACTION_DETAILS':
        return paymentTransactionDetailsInitialState
      default:
        return state
    }
  }

  export {
    walletHistoryData,
    paymentHistoryData,
    paymentTransactionDetails
  };