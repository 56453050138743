const dashboardInitialState = {
    todaysDetails : {},
    monthlyEarningGraphDetails : {},
    monthlyUnitConsumeGraphDetails : {},
    organizationEarningGraphDetails : {},
    organizationUnitConsumeGraphDetails : {},
    chargingStationsMarkers : {}
}

const dashboardDetails = (state = dashboardInitialState, action) => {
    switch (action.type) {
        case 'SET_TODAYS_DASHBOARD_DETAILS':
            return {
                ...state,
                todaysDetails: action.payload
            }
        case 'SET_MONTHLY_EARNING_GRAPH_DETAILS':
            return {
                ...state,
                monthlyEarningGraphDetails: action.payload
            }
        case 'SET_MONTHLY_UNIT_CONSUME_GRAPH_DETAILS':
            return {
                ...state,
                monthlyUnitConsumeGraphDetails: action.payload
            }
        case 'SET_ORGNIZATION_EARNING_GRAPH_DETAILS':
            return {
                ...state,
                organizationEarningGraphDetails: action.payload
            }
        case 'SET_ORGNIZATION_UNIT_CONSUME_GRAPH_DETAILS':
            return {
                ...state,
                organizationUnitConsumeGraphDetails: action.payload
            }
        case 'SET_CHARGING_STATION_MARKER_DETAILS':
            return {
                ...state,
                chargingStationsMarkers: action.payload
            }
        default:
            return state
    }
}

export {
    dashboardDetails
};