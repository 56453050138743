import { combineReducers } from 'redux';

import {  AuthState } from './auth.reducer'
import {  changeHeaderState } from './sidebar.reducer'
import {  organizationList } from './organization.reducer'
import {  profileInfo, userListData, userDetails } from './user.reducer'
import { chargingStationListData, chargingStationDetails, allChargingStationList, chargingHistoryData, chargingTransactionDetails } from './charging-station.reducer'
import { walletHistoryData, paymentHistoryData, paymentTransactionDetails } from './payment.reducer'
import { loader } from './loader.reducer'
import { dashboardDetails } from './dashboard.reducer'
import { masterFacilityList, masterAmenityTypeList, masterEVList, requestReportHistoryList, requestedEvseList, acceptedEvseList, rejectedEvseList } from './common.reducer'
import { notificationList } from '../reducers/notifications.reducer';
import { chargingStatus, modelSize } from './live-charging.reducer'
import { websocketReducer } from './websocket.reducer';
import { RfidList, UserList } from './rfid.reducer';
import { chargingSummary, initiateCharging } from './startCharging.reducer'

const rootReducer = combineReducers({
    websocket: websocketReducer,

    loader,

    dashboardDetails,
    
    changeHeaderState,

    AuthState,

    profileInfo,
    userListData,
    userDetails,

    chargingStationListData,
    chargingStationDetails,
    allChargingStationList,
    chargingHistoryData,
    chargingTransactionDetails,

    walletHistoryData,
    paymentHistoryData,
    paymentTransactionDetails,

    organizationList,

    masterFacilityList,
    masterAmenityTypeList,
    masterEVList,
    requestReportHistoryList,
    
    requestedEvseList,
    acceptedEvseList,
    rejectedEvseList,

    notificationList,
    chargingStatus,
    modelSize,

    RfidList,
    UserList,

    chargingSummary,
    initiateCharging,
});

export default rootReducer;