const chargingStationInitialState = {
    chargingstationlist: [],
    pagination: {}
  }

  const chargingHistoryInitialState = {
    chargingTransactionList: [],
    pagination: {}
  }
  const chargingTransactionDetailsInitialState = {}
  // const chargingStationDetailsInitialState = {}
  const chargingStationDetailsInitialState = {
    "stationDetails": {
      "id": null,
      "stationId": "",
      "stationName": "",
      "stationStatus": "",
      "organization": {
        "id": null,
        "name": "",
        "logo": null,
        "prefix": "",
      },
      "address": {
        "area": "-",
        "city": "-",
        "postal": "0000",
        "countryCode": "",
        "coordinates": {
          "latitude": "",
          "Longitude": ""
        },
        "locationType": "",
      },
      "openTime": "",
      "contactInfo": {
        "email": "",
        "mobile": "",
      },
      "facilities": [],
      "amenities": [],
      "images": [],
    },

    "connectorDetails": {
      "machineList": [],
    }
  }
  
  const chargingStationListData = (state = chargingStationInitialState, action) => {
    switch (action.type) {
      case 'SET_CHARGING_STATION_LIST_SUCCESS':
        return {
          ...state,
          chargingstationlist: action.payload.chargingStationList,
          pagination: {
            currentPage: action.payload.currentPage,
            totalPages: action.payload.totalPages
          }
        }
      case 'RESET_CHARGING_STATION_LIST':
        return chargingStationInitialState
      default:
        return state
    }
  }

  const chargingStationDetails = (state = chargingStationDetailsInitialState, action) => {
    switch (action.type) {
      case 'SET_CHARGING_STATION_DETAILS_SUCCESS':
        return action.payload
      case 'RESET_CHARGING_STATION_DETAILS':
        return chargingStationDetailsInitialState
      case 'SET_CHARGING_STATION_DETAILS_LIVE':
        let newState = { ...state };
        newState.stationDetails.stationStatus = action.payload[0]
        newState.connectorDetails.machineList.forEach(machine => {
          let payloadMachine = action.payload[1].find(payloadMachine => payloadMachine.machineId === machine.machineId);
          if (payloadMachine) {
            machine.Connectors.forEach(connector => {
              let payloadConnector = payloadMachine.Connectors.find(
                payloadConnector => payloadConnector.connector_id === connector.connector_id
              );
              if (payloadConnector) {
                Object.assign(connector, payloadConnector);
              }
            });
          }
        });
        return newState
      default:
        return state
    }
  }

  const chargingHistoryData = (state = chargingHistoryInitialState, action) => {
    switch (action.type) {
      case 'SET_CHARGING_TRANSACTION_LIST_SUCCESS':
        return {
          ...state,
          chargingTransactionList: action.payload.chargingTransactionList,
          pagination: {
            currentPage: action.payload.currentPage,
            totalPages: action.payload.totalPages
          }
        }
      case 'RESET_CHARGING_TRANSACTION_LIST':
        return chargingHistoryInitialState
      default:
        return state
    }
  }

  const chargingTransactionDetails = (state = chargingTransactionDetailsInitialState, action) => {
    switch (action.type) {
      case 'SET_CHARGING_TRANSACTION_DETAILS_SUCCESS':
        return action.payload
      case 'RESET_CHARGING_TRANSACTION_DETAILS':
        return chargingTransactionDetailsInitialState
      default:
        return state
    }
  }

  const allChargingStationList = (state = {chargingstationlist:[]}, action ) => {
    switch (action.type) {
      case 'SET_ALL_CHARGING_STATION_LIST_SUCCESS':
        return {
          ...state,
          chargingstationlist: action.payload,
        }
      default:
        return state
    }
  }

  export {
    chargingStationListData,
    chargingStationDetails,
    chargingHistoryData,
    chargingTransactionDetails,
    allChargingStationList,
  };