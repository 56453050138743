const rfidInitialState = {
    rfidList: [],
    pagination: {}
}
const userListInitialState = []

const RfidList = (state = rfidInitialState, action)=>{
    switch (action.type) {
        case 'SET_RFID_LIST_SUCCESS':
            return {
                ...state,
                rfidList: action.payload.rfidData,
                pagination: {
                    currentPage: action.payload.currentPage,
                    totalPages: action.payload.totalPages
                }
            }
        default:
            return state
    }
}

const UserList = (state = userListInitialState, action)=>{
    switch (action.type) {
        case 'SET_USER_LIST_DROPDOWN_SUCCESS':
            return action.payload
        default:
            return state
    }
}

export {
    RfidList,
    UserList,
}