export const  commonConstants = {
    SHOW_LOADER : "SHOW_LOADER",
    HIDE_LOADER : "HIDE_LOADER",

    USER_AUTH: "USER_AUTH",
    USER_NOT_AUTH: "USER_NOT_AUTH",

    LOGIN_SUCCESS : "LOGIN_SUCCESS",
    LOGIN_FAILED : "LOGIN_FAILED",
    LOGOUT : "LOGOUT",
    Restore_Token_and_User : "Restore_Token_and_User",

    SIDEBAR_SHOW : 'SIDEBAR_SHOW',
    SET_TOAST: "SET_TOAST",

    //Dashboard
    SET_TODAYS_DASHBOARD_DETAILS : "SET_TODAYS_DASHBOARD_DETAILS",
    SET_MONTHLY_EARNING_GRAPH_DETAILS : "SET_MONTHLY_EARNING_GRAPH_DETAILS",
    SET_MONTHLY_UNIT_CONSUME_GRAPH_DETAILS : "SET_MONTHLY_UNIT_CONSUME_GRAPH_DETAILS",
    SET_ORGNIZATION_EARNING_GRAPH_DETAILS : "SET_ORGNIZATION_EARNING_GRAPH_DETAILS",
    SET_ORGNIZATION_UNIT_CONSUME_GRAPH_DETAILS : "SET_ORGNIZATION_UNIT_CONSUME_GRAPH_DETAILS",
    SET_CHARGING_STATION_MARKER_DETAILS : "SET_CHARGING_STATION_MARKER_DETAILS",

    SET_USER_LIST_SUCCESS : 'SET_USER_LIST_SUCCESS',
    SET_USER_LIST_FAILED : 'SET_USER_LIST_FAILED',
    RESET_USER_LIST : 'RESET_USER_LIST',

    SET_MY_PROFILE_SUCCESS : 'SET_MY_PROFILE_SUCCESS',
    SET_MY_PROFILE_FAILED : 'SET_MY_PROFILE_FAILED',
    RESET_MY_PROFILE: 'RESET_MY_PROFILE',

    SET_USER_DETAILS_SUCCESS : 'SET_USER_DETAILS_SUCCESS',
    SET_USER_DETAILS_FAILED : 'SET_USER_DETAILS_FAILED',
    RESET_USER_DETAILS: 'RESET_USER_DETAILS',

    SET_CHARGING_STATION_LIST_SUCCESS : 'SET_CHARGING_STATION_LIST_SUCCESS',
    SET_CHARGING_STATION_LIST_FAILED : 'SET_CHARGING_STATION_LIST_FAILED',
    RESET_CHARGING_STATION_LIST: 'RESET_CHARGING_STATION_LIST',
    SET_ALL_CHARGING_STATION_LIST_SUCCESS: "SET_ALL_CHARGING_STATION_LIST_SUCCESS",

    SET_PAYMENT_HISTORY_SUCCESS : 'SET_PAYMENT_HISTORY_SUCCESS',
    SET_PAYMENT_HISTORY_FAILED : 'SET_PAYMENT_HISTORY_FAILED',
    RESET_PAYMENT_HISTORY: 'RESET_PAYMENT_HISTORY',

    SET_PAYMENT_TRANSACTION_DETAILS_SUCCESS : 'SET_PAYMENT_TRANSACTION_DETAILS_SUCCESS',
    SET_PAYMENT_TRANSACTION_DETAILS_FAILED : 'SET_PAYMENT_TRANSACTION_DETAILS_FAILED',
    RESET_PAYMENT_TRANSACTION_DETAILS: 'RESET_PAYMENT_TRANSACTION_DETAILS',

    SET_WALLET_HISTORY_SUCCESS : 'SET_WALLET_HISTORY_SUCCESS',
    SET_WALLET_HISTORY_FAILED : 'SET_WALLET_HISTORY_FAILED',
    RESET_WALLET_HISTORY: 'RESET_WALLET_HISTORY',

    SET_CHARGING_TRANSACTION_LIST_SUCCESS : 'SET_CHARGING_TRANSACTION_LIST_SUCCESS',
    SET_CHARGING_TRANSACTION_LIST_FAILED : 'SET_CHARGING_TRANSACTION_LIST_FAILED',
    RESET_CHARGING_TRANSACTION_LIST: 'RESET_CHARGING_TRANSACTION_LIST',


    SET_CHARGING_TRANSACTION_DETAILS_SUCCESS : 'SET_CHARGING_TRANSACTION_DETAILS_SUCCESS',
    SET_CHARGING_TRANSACTION_DETAILS_FAILED : 'SET_CHARGING_TRANSACTION_DETAILS_FAILED',
    RESET_CHARGING_TRANSACTION_DETAILS: 'RESET_CHARGING_TRANSACTION_DETAILS',

    SET_CHARGING_STATION_DETAILS_SUCCESS : 'SET_CHARGING_STATION_DETAILS_SUCCESS',
    SET_CHARGING_STATION_DETAILS_FAILED : 'SET_CHARGING_STATION_DETAILS_FAILED',
    RESET_CHARGING_STATION_DETAILS: 'RESET_CHARGING_STATION_DETAILS',
    SET_CHARGING_STATION_DETAILS_LIVE: 'SET_CHARGING_STATION_DETAILS_LIVE',

    SET_ORGANIZATION_LIST_SUCCESS: 'SET_ORGANIZATION_LIST_SUCCESS',

    SET_FACILITY_LIST_SUCCESS: "SET_FACILITY_LIST_SUCCESS",
    SET_AMENITY_TYPE_LIST_SUCCESS: "SET_AMENITY_TYPE_LIST_SUCCESS",

    SET_EV_LIST_SUCCESS : 'SET_EV_LIST_SUCCESS',

    SET_REQUEST_REPORT_HISTORY_LIST_SUCCESS : 'SET_REQUEST_REPORT_HISTORY_LIST_SUCCESS',
    // SET_EVSE_LIST_SUCCESS : 'SET_EVSE_LIST_SUCCESS'
    SET_REQUESTED_EVSE_LIST_SUCCESS: 'SET_REQUESTED_EVSE_LIST_SUCCESS',
    SET_ACCEPTED_EVSE_LIST_SUCCESS: 'SET_ACCEPTED_EVSE_LIST_SUCCESS',
    SET_REJECTED_EVSE_LIST_SUCCESS: 'SET_REJECTED_EVSE_LIST_SUCCESS',

    SET_NOTIFICATION_LIST_SUCCESS: 'SET_NOTIFICATION_LIST_SUCCESS',

    SET_RFID_LIST_SUCCESS: 'SET_RFID_LIST_SUCCESS',
    SET_USER_LIST_DROPDOWN_SUCCESS: 'SET_USER_LIST_DROPDOWN_SUCCESS',
    
    // SET_CHARGING_STATUS_SUCCESS: 'SET_CHARGING_STATUS_SUCCESS',
    // SET_INITIAL_CHARGING_STATUS_SUCCESS: 'SET_INITIAL_CHARGING_STATUS_SUCCESS',
    SET_LIVE_CHARGING_DATA_GET_SUCCESS: 'SET_LIVE_CHARGING_DATA_GET_SUCCESS',
    SET_CHARGING_TRANS_DETAIL_MODEL_SIZE: 'SET_CHARGING_TRANS_DETAIL_MODEL_SIZE',
    
    SET_CHARGING_SUMMARY_DATA_SUCCESS: 'SET_CHARGING_SUMMARY_DATA_SUCCESS',
    SET_INITIATE_CHARGING_DATA_SUCCESS: 'SET_INITIATE_CHARGING_DATA_SUCCESS',
}