const userInitialState = {
  userList: [],
  pagination: {}
}

const defultProfleState = {};

const defultUserDetailsState = {}; 

const profileInfo = (state = defultProfleState, action) => {
  switch (action.type) {
    case 'SET_MY_PROFILE_SUCCESS':
      return action.payload.profileDetails
    case 'RESET_MY_PROFILE':
      return defultProfleState
    default:
      return state
  }
}

const userListData = (state = userInitialState, action) => {
  switch (action.type) {
    case 'SET_USER_LIST_SUCCESS':
      return {
        ...state,
        userList: action.payload.userList,
        pagination: {
          nextPage: action.payload.nextPageNumber,
          prevPage: action.payload.prevPageNumber,
          totalPages: action.payload.totalPages
        }
      }
    case 'RESET_USER_LIST':
      return userInitialState
    default:
      return state
  }
}

const userDetails = (state = defultUserDetailsState, action) => {
  switch (action.type) {
    case 'SET_USER_DETAILS_SUCCESS':
      return action.payload.userDetails
    case 'RESET_USER_DETAILS':
      return defultUserDetailsState
    default:
      return state
  }
}

export {
  userListData,
  profileInfo,
  userDetails
};