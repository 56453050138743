const notificationsInitialState = {
    count:0,
    currentPage:1,
    totalPages:1,
    notifications:[]
}

const notificationList = (state = notificationsInitialState, action) => {
    switch (action.type) {
        case 'SET_NOTIFICATION_LIST_SUCCESS':
            return action.payload
        default:
            return state
    }
}

export {
    notificationList,
  };