const masterFacilityInitialState = []
const masterAmenityInitialState = []
const masterEVInitialState = []
const requestReportInitialState = []
const requestedEvseInitialState = []
const acceptedEvseInitialState = []
const rejectedEvseInitialState = []

const masterFacilityList = (state = masterFacilityInitialState, action) => {
    switch (action.type) {
        case 'SET_FACILITY_LIST_SUCCESS':
            return action.payload
        default:
            return state
    }
}

const masterAmenityTypeList = (state = masterAmenityInitialState, action) => {
    switch (action.type) {
        case 'SET_AMENITY_TYPE_LIST_SUCCESS':
            return action.payload
        default:
            return state
    }
}

const masterEVList = (state = masterEVInitialState, action) => {
    switch (action.type) {
        case 'SET_EV_LIST_SUCCESS':
            return action.payload
        default:
            return state
    }
}

const requestReportHistoryList = (state = requestReportInitialState, action) => {
    switch (action.type) {
        case 'SET_REQUEST_REPORT_HISTORY_LIST_SUCCESS':
            return action.payload
        default:
            return state
    }
}
const requestedEvseList = (state = requestedEvseInitialState, action) => {
    switch (action.type) {
        case 'SET_REQUESTED_EVSE_LIST_SUCCESS':
            return action.payload
        default:
            return state
    }
}

const acceptedEvseList = (state = acceptedEvseInitialState, action) => {
    switch (action.type) {
        case 'SET_ACCEPTED_EVSE_LIST_SUCCESS':
            return action.payload
        default:
            return state
    }
}

const rejectedEvseList = (state = rejectedEvseInitialState, action) => {
    switch (action.type) {
        case 'SET_REJECTED_EVSE_LIST_SUCCESS':
            return action.payload
        default:
            return state
    }
}

export {
    masterFacilityList,
    masterAmenityTypeList,
    masterEVList,
    requestReportHistoryList,
    requestedEvseList,
    acceptedEvseList,
    rejectedEvseList,
};